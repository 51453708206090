import "./styles.css";
export default function WeekList({ days, setLock, printMode }) {
  console.log("dd", days, typeof days);
  return (
    <div className="weekdays">
      {days.map((d) => (
        <div className="day-container" key={d.day}>
          <div className="day">{d.day}</div>
          {d.link ? (
            <div className="meal">
              <a href={d.link} rel="noopener noreferrer" target="_blank">
                {d.meal}
              </a>
            </div>
          ) : (
            <div className="meal">{d.meal}</div>
          )}
          {!printMode && d.meal && (
            <div className="lockGroup">
              {d.isLocked && <span>locked</span>}

              {setLock && (
                <div className="lockBtn">
                  <button onClick={() => setLock(d)}>Lock</button>
                </div>
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
