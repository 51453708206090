import React, { useState } from "react";
import "./App.css";
import foodlist from "./mealplans/simplefoods.json";
import compfood from "./mealplans/complicatedfood.json";
import bakelist from "./mealplans/sweets.json";
import foodpic from "./images/kathyfoodpic.jpg";

import ShopListContainer from "./components/shoppingListContainer/shopListContainer";
import WeekList from "./components/weeklist/WeekList";

const daysInitial = [
  {
    day: "Monday",
    meal: "",
  },
  {
    day: "Tuesday",
    meal: "",
  },
  {
    day: "Wednesday",
    meal: "",
  },
  {
    day: "Thursday",
    meal: "",
  },
  {
    day: "Friday",
    meal: "",
  },
  {
    day: "Saturday",
    meal: "",
  },
  {
    day: "Sunday",
    meal: "",
  },
];

function Header() {
  return (
    <div className="h1Container">
      <h1>Weekly Meal Generator</h1>
    </div>
  );
}
function HeaderImg({ printMode }) {
  if (printMode) return null;
  return (
    <div className="mainImage">
      <img src={foodpic} alt="foodpic" />
    </div>
  );
}

function App() {
  const [days, setDays] = useState(daysInitial);
  const [sweetsResult, setSweetResult] = useState(daysInitial);
  const [printMode, setPrintMode] = useState(false);

  function generateMealsList(foodlist, days) {
    return days.map((element) => {
      if (element?.isLocked) {
        return element;
      }
      const i = Math.floor(Math.random() * foodlist.length);
      const day = element.day;
      const meal = foodlist[i].title;
      const link = foodlist[i].link;

      const ret = {
        day: day,
        meal: meal,
        link,
      };
      return ret;
    });
  }
  function generateMeals(foodlist) {
    const _days = generateMealsList(foodlist, days);
    setDays(_days);
  }
  function generateSweet(foodlist) {
    const _days = generateMealsList(foodlist, sweetsResult);
    setSweetResult(_days);
  }

  function generateSimpleMeals() {
    generateMeals(foodlist);
  }

  function generateCompMeals() {
    generateMeals(compfood);
  }

  function generateBothLists() {
    const l = [...foodlist, ...compfood];
    generateMeals(l);
  }

  function setLock(dayToLock, days) {
    return days.map((d) => {
      if (d.day === dayToLock.day) {
        if (d.isLocked) {
          d.isLocked = false;
        } else {
          d.isLocked = true;
        }
      }
      return d;
    });
  }

  function setLockSnack(dayToLock) {
    const _days = setLock(dayToLock, days);
    setDays(_days);
  }

  function resetMeals() {
    setDays(daysInitial);
  }

  function generateSweets() {
    generateSweet(bakelist);
  }
  function setLockSweets(dayToLock) {
    const _days = setLock(dayToLock, sweetsResult);
    setSweetResult(_days);
  }

  function resetSweets() {
    setSweetResult(daysInitial);
  }
  function handlePrint() {
    if (!printMode) {
      setPrintMode(true);
      setTimeout(() => {
        window.print();
      }, 500);
    } else {
      setPrintMode(false);
    }
  }

  return (
    <div className="App">
      <HeaderImg printMode={printMode} />
      <Header />
      <div className="maingroup">
        <div className="foodgroups">
          <div className="foodGroup">
            <h2>SNACKS & MEALS</h2>
            <WeekList
              days={days}
              setLock={setLockSnack}
              printMode={printMode}
            />
            {!printMode && (
              <div className="generatorButtonGroup">
                <button onClick={generateSimpleMeals}>Salad&Snacks</button>
                <button onClick={generateCompMeals}>Meals</button>
                <button onClick={generateBothLists}>Snacks&Meals</button>
                <button onClick={resetMeals}>RESET</button>
              </div>
            )}
          </div>
          <div className="foodGroup">
            <h2>SWEETS & BAKES</h2>
            <WeekList
              days={sweetsResult}
              setLock={setLockSweets}
              printMode={printMode}
            />
            {!printMode && (
              <div className="generatorButtonGroup">
                <button onClick={generateSweets}>Let's Bake!</button>
                <button onClick={resetSweets}>RESET</button>
              </div>
            )}
          </div>
        </div>

        <div className="shoplistcontainer-container">
          <ShopListContainer />
        </div>
      </div>

      <button className="printButton" onClick={handlePrint}>
        print
      </button>
    </div>
  );
}

export default App;
